/**
 * External Dependencies
 */
import 'jquery';
import 'bootstrap';

$(document).ready(() => {

    /* Slicknav */
    $('.main_nav').slicknav({
        prependTo:'.slicknav'
    });

    $('.slicknav_nav a').parent('a').addClass("hasChild");
    

    /* Page Content */
    $('.page-content').not(":has('.hero')").addClass('moretop');
    
    /* Text */
    var $thalfheight = $('.section.text').outerHeight(true) / 2;

    var $hihalfheight = $('.hero-image').outerHeight(true);

    $('.section.text').css({ 'margin-top': -$thalfheight + 'px' });
    
    $('.homepage .hero-image').css({ 'padding-bottom': $thalfheight + 100 + 'px' });
    
    $('.default').css({ 'margin-top': -$hihalfheight + 150 + 'px' });
    
    $(window).on('resize', function () {
        var $thalfheight = $('.section.text').outerHeight(true) / 2;
        var $hihalfheight = $('.hero-image').outerHeight(true);
        $('.section.text').css({ 'margin-top': -$thalfheight + 'px' });
        $('.homepage .hero-image').css({ 'padding-bottom': $thalfheight + 100 + 'px' });
        $('.default').css({ 'margin-top': -$hihalfheight + 150 + 'px' });
    });
    
    $('.accordion').find('.accordion-section-title').click(function(){
        $(this).next().slideToggle('fast');
        $(this).toggleClass('open');
    });
    
    // Commented out when the portfolio functions changed. 
    //$('.portfolio').find('.portfolio-closed').click(function(){
    //  $(this).parent().toggleClass('open');
    //});
    //$('.portfolio').find('.portfolio-content').click(function(){
    //  $(this).parent().toggleClass('open');
    //});
    
    /* Smooth scrolling to anchor */
    var offset = $(".header").innerHeight();
    if (offset.length) {
        $('a[href*=#]:not([href=#])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') 
                || location.hostname == this.hostname) {
        
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                   if (target.length) {
                     $('html,body').animate({
                         scrollTop: target.offset().top - offset
                    }, 1000);
                    return false;
                }
            }
        });
        /* Smooth scrolling to anchor from another page */
        $('html, body').animate({ scrollTop: $(window.location.hash).offset().top - offset }, 1000);
    }
});
